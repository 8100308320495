<script lang="ts">
  import DocumentTypeSelector from '$lib/components/documents/DocumentTypeSelector.svelte';
  import FeedbackTab from '$lib/components/documents/FeedbackTab.svelte';
  import StudentDocumentTab from '$lib/components/documents/StudentDocumentTab.svelte';

  import { DOCUMENTS_TYPES } from '$lib/utils/constants';
  import { isSmallScreen } from '$lib/utils/window';

  let documentType: keyof typeof DOCUMENTS_TYPES = 'generalDocuments';
  let showTabSelector = true;

  function tabChangedHandler(tabChangedEvent: CustomEvent) {
    documentType = tabChangedEvent.detail;
  }

  function documentPreviewToggledHandler(documentPreviewToggledEvent: CustomEvent) {
    showTabSelector = documentPreviewToggledEvent.detail;
  }
</script>

<div class="h-full w-full px-5 bg-ai8-white flex flex-col dark:bg-ai8-chat-gray">
  {#if !isSmallScreen()}
    <h1 class="text-xl/[27px] mt-3 font-bold">Documents</h1>
  {/if}

  {#if showTabSelector}
    <DocumentTypeSelector bind:documentType />
  {/if}

  {#if documentType === DOCUMENTS_TYPES.generalDocuments}
    <StudentDocumentTab on:tabChange={tabChangedHandler} on:documentPreviewToggled={documentPreviewToggledHandler} />
  {:else}
    <FeedbackTab on:tabChange={tabChangedHandler} on:documentPreviewToggled={documentPreviewToggledHandler} />
  {/if}
</div>
